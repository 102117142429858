<template>
    <div>
        <div
            :class="wrapperClassName"
            class="bg-gray-200"
        >
            <div class="md:hidden w-screen flex items-center pt-6 justify-between flex-shrink-0 h-16 px-6">
                <div
                    v-if="currentUser"
                    class="flex max-w-full overflow-hidden"
                >
                    <img
                        class="w-11 h-11 rounded-full"
                        :src="currentUser.avatarUrl || require('@/assets/svg/user-circle.svg')"
                        :alt="currentUser.firstName"
                    />
                    <div class="ml-3">
                        <p class="font-ffdin text-sm tracking-wide3 font-bold uppercase">{{ $t('app.title') }}</p>
                        <p class="font-light text-base leading-6 block text-gray-700 greetings">
                            {{ $t('app.welcome') }} <span class="font-bold">{{ currentUser.firstName }}</span>
                        </p>
                    </div>
                </div>
                <span class="flex-grow" />
                <mobile-menu
                    :routes="routes"
                    class="uppercase font-bold text-2xl flex"
                />
            </div>
        </div>
        <div class="hidden md:flex items-center h-20 px-4">
            <div class="font-ffdin hidden lg:block font-bold uppercase text-2xl shadow-inner">
                {{ $t('app.title') }}
            </div>
            <div class="flex-grow hidden lg:flex" />
            <template v-if="currentUser">
                <div class="shadow-lg rounded-full p-0.5 mr-3">
                    <img
                        class="w-11 h-11 rounded-full"
                        :src="currentUser.avatarUrl || require('@/assets/svg/user-circle.svg')"
                        :alt="currentUser.firstName"
                    />
                </div>
                <div class="mr-3">
                    <p class="font-ffdin text-sm tracking-wide3 font-bold uppercase lg:hidden">
                        {{ $t('app.title') }}
                    </p>
                    <p class="font-light text-base text-gray-700 greetings">
                        {{ $t('app.welcome') }} <span class="font-bold">{{ currentUser.firstName }}</span>
                    </p>
                </div>
            </template>
            <ActionButton
                v-if="actionRoutes.length"
                :routes="actionRoutes"
                listClassName="listClassName"
                class="hidden md:block"
            />
            <div class="flex-grow lg:hidden" />
            <a
                ref="chooser"
                href="#unit-chooser"
                class="flex items-center max-w-full overflow-hidden"
            >
                <icon
                    name="unit-personal"
                    class="w-12 h-12 text-gray-600 mr-4"
                />
                <div
                    v-if="currentProfile"
                    class="location"
                >
                    <div class="flex">
                        <span class="font-ffdin font-bold text-xs uppercase whitespace-nowrap">{{ currentProfile.communityName }}</span>
                        <icon
                            name="chevron-down"
                            class="ml-2 w-3 h-3"
                        />
                    </div>
                    <div class="font-sofia text-md truncate">{{ currentProfile.locationName }}</div>
                </div>
            </a>
            <Notifications />
        </div>
        <div id="unit-chooser">
            <div class="w-full h-full border rounded-lg bg-white shadow-2xl">
                <ContentArea>
                    <template v-slot:header>
                        <div class="flex justify-between items-center mx-6 mt-6 pb-4 border-b">
                            <div class="section-title">select unit</div>
                            <icon
                                name="close"
                                class="w-6 h-6 cursor-pointer"
                                @click="close"
                            />
                        </div>
                    </template>
                    <template v-slot:default>
                        <div
                            v-for="community in profiles"
                            :key="community.communityId"
                        >
                            <div class="community">
                                {{ community.communityName }}
                            </div>
                            <div
                                v-for="profile in filteredProfiles(community.profiles)"
                                :key="profile.profileId"
                            >
                                <div
                                    class="unit-button"
                                    :class="{
                                        active: currentProfile && currentProfile.profileId === profile.profileId,
                                    }"
                                    @click="handleProfileClick(profile)"
                                >
                                    <div class="unit">{{ profile.locationName }}</div>
                                    <icon
                                        v-if="currentProfile && currentProfile.profileId === profile.profileId"
                                        name="checkmark"
                                        class="absolute right-6 w-8 h-8"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </ContentArea>
            </div>
        </div>
    </div>
</template>

<script>
import MobileMenu from '@/components/root/MobileMenu';
import Icon from '@/components/ui/Icon';
import Notifications from '@/components/root/Notifications';
import ActionButton from '@/components/root/ActionButton';
import ContentArea from '@/components/ui/ContentArea';
import AuthMixin from '@/mixins/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import AppsPermissionsFetcher from '@/mixins/AppsPermissionsFetcher';
import { PROFILE_TYPES } from '@/utils/constants/leasing';

const ACTION_ROUTES = ['payments.create', 'serviceRequests.create'];

export default {
    name: 'TopHeader',

    components: { ContentArea, MobileMenu, Icon, Notifications, ActionButton },

    mixins: [AuthMixin, NotifyMixin, AppsPermissionsFetcher, AccountingQueryManager],

    props: {
        routes: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
        }),

        actionRoutes() {
            return this.collectRoutes(ACTION_ROUTES);
        },

        wrapperClassName() {
            return this.$route.name === 'dashboard.index' ? 'mobileWrapper-home-page' : 'mobileWrapper';
        },
    },

    watch: {
        currentProfile(val) {
            if (val) {
                this.getCurrentUser();
            }
        },
    },

    mounted() {
        this.$watch('currentProfile', currentProfile => {
            if (!currentProfile && this.profiles.length) {
                this.$refs.chooser?.click();
            }
        });

        this.$watch('profiles', profiles => {
            if (!this.currentProfile && profiles.length) {
                this.$refs.chooser?.click();
            }
        });
    },

    methods: {
        ...mapActions({
            setLeaseAgreementLog: 'accounting/setLeaseAgreementLog',
        }),

        filteredProfiles(profiles) {
            if (profiles?.length > 1) {
                const residents = profiles.filter(item => item.type === PROFILE_TYPES.resident);
                const applicants = profiles.filter(item => item.type === PROFILE_TYPES.applicant);
                const residentUnitsIds = new Set();
                residents.forEach(resident => residentUnitsIds.add(resident.locationId));
                const filteredApplicants = applicants.filter(applicant => !residentUnitsIds.has(applicant.locationId));

                return [...residents, ...filteredApplicants];
            }

            return profiles;
        },

        handleProfileClick(profile) {
            this.setCurrentProfile(profile);
            this.close();
        },

        close() {
            this.$router.back();
        },

        collectRoutes(names) {
            return names
                .map(name => {
                    const route = this.availableRoutes.find(route => route.name === name);

                    if (route) {
                        return {
                            name: route.name,
                            ...route.meta,
                        };
                    }
                })
                .filter(route => route);
        },

        async getCurrentUser() {
            try {
                const res = await this.queryHandler('currentUser', 'resident');
                this.setCurrentUser(res);
            } catch (error) {
                this.notifyError(error.message);
            }
        },
    },
};
</script>

<style scoped>
:deep(.listClassName) {
    top: 4.563rem;
    right: 12.688rem;
}
.mobileWrapper-home-page {
    height: 10rem;
}

.mobileWrapper {
    height: 5rem;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .mobileWrapper-home-page {
        height: auto;
    }

    .mobileWrapper {
        height: auto;
        margin-bottom: 0px;
    }
}

.location {
    max-width: 9.375rem;
}
.greetings {
    letter-spacing: 0;
}
#unit-chooser {
    @apply fixed hidden z-40 top-0 left-1/2 transform -translate-x-1/2 w-screen max-w-3xl h-screen p-4;
}

#unit-chooser:target {
    @apply block;
}

.community {
    @apply sticky z-10 top-0 pt-6 pb-3 bg-white font-ffdin font-bold uppercase text-md max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis;
}

.unit-button {
    @apply relative flex flex-col justify-center items-center border rounded-xl mb-2 font-ffdin bg-gray-100 border-gray-200 text-gray-400 cursor-pointer;
    height: 7.25rem;
}

.unit-button.active {
    @apply border-2 bg-white border-gray-700 text-gray-700;
}

.unit-button .unit {
    @apply max-w-full px-4 whitespace-nowrap overflow-hidden overflow-ellipsis font-medium;
    font-size: 30px;
}

.unit-button.active .unit {
    @apply px-14;
}

.unit-button .building {
    @apply max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis text-base font-medium;
}
</style>
